import {isMobileFirefox} from "./utils";

export class MeshAnimation {
    constructor(options) {
        // if(isMobileFirefox()) return
        this.options = options || {};
        this.element = document.querySelector(this.options.element);
        this.canvas = null;
        this.ctx = null;
        this.offscreenCanvas = null; // Bufor do rysowania
        this.offscreenCtx = null; // Kontekst dla bufora
        this.particles = [];
        this.isAnimating = false; // Flaga dla animacji
        this.connectDistance = this.getConnectDistance(this.options.connectDistance);
        this.maxParticles = this.options.maxParticles || 100;
        this.particleRadius = this.options.particleRadius || 2;
        this.maxConnections = this.options.maxConnections || 5;
        this.animationSpeed = this.options.animationSpeed || 1;
        this.observer = null; // Przechowuje obiekt IntersectionObserver
        this.lastFrameTime = 0; // Dla ograniczenia FPS
        this.resizeTimeout = null; // Timeout do debouncingu resize

        this.init();
    }

    init() {
        if (!this.element) {
            console.error("Element nie znaleziony");
            return;
        }

        this.createCanvas();
        this.resizeCanvas(); // Zmiana rozmiaru canvasu
        window.addEventListener('resize', this.resizeCanvas.bind(this));

        this.setupObserver();
        this.initParticles(); // Teraz inicjalizacja po pełnym resize
    }

    getMaxSize() {
        if (this.element.clientWidth > this.element.clientHeight) return this.element.clientWidth;
        else return this.element.clientHeight;
    }

    getConnectDistance(optionsMultiplier) {
        const multiplier = optionsMultiplier || 1;
        const size = (this.getMaxSize() / 12) * multiplier;
        if (size < 100) return 100;
        if (size > 500) return 500;
        return size;
    }

    createCanvas() {
        this.canvas = document.createElement('canvas');
        this.canvas.style.position = 'absolute';
        this.canvas.style.width = '100%';
        this.canvas.style.height = '100%';
        this.canvas.style.top = 0;
        this.canvas.style.left = 0;
        this.element.appendChild(this.canvas);

        this.ctx = this.canvas.getContext('2d');

        // Tworzenie bufora rysowania (canvas w pamięci)
        this.offscreenCanvas = document.createElement('canvas');
        this.offscreenCanvas.width = this.canvas.width;
        this.offscreenCanvas.height = this.canvas.height;
        this.offscreenCtx = this.offscreenCanvas.getContext('2d');
    }

    resizeCanvas() {
        clearTimeout(this.resizeTimeout);
        this.resizeTimeout = setTimeout(() => {
            this.canvas.width = this.element.clientWidth;
            this.canvas.height = this.element.clientHeight;

            // Zmiana rozmiaru offscreen canvas
            this.offscreenCanvas.width = this.canvas.width;
            this.offscreenCanvas.height = this.canvas.height;

            // Zainicjuj ponownie cząstki po zmianie rozmiaru
            this.initParticles();
        }, 200);  // Debouncing resize na 200 ms
    }

    initParticles() {
        this.particles = [];
        for (let i = 0; i < this.maxParticles; i++) {
            const radius = this.particleRadius;
            const x = Math.random() * (this.canvas.width - radius * 2) + radius;
            const y = Math.random() * (this.canvas.height - radius * 2) + radius;
            const dx = (Math.random() - 0.5) * 2;
            const dy = (Math.random() - 0.5) * 2;
            this.particles.push(new Particle(x, y, dx, dy, radius, this.canvas, this.ctx, this.animationSpeed));
        }
    }

    connectParticles(ctx = this.offscreenCtx) {
        for (let i = 0; i < this.particles.length; i++) {
            let connections = 0;
            for (let j = i + 1; j < this.particles.length && connections < this.maxConnections; j++) {
                const distance = Math.hypot(this.particles[i].x - this.particles[j].x, this.particles[i].y - this.particles[j].y);
                if (distance < this.connectDistance) {
                    ctx.beginPath();
                    ctx.moveTo(this.particles[i].x, this.particles[i].y);
                    ctx.lineTo(this.particles[j].x, this.particles[j].y);
                    ctx.strokeStyle = `rgba(255, 255, 255, ${1 - distance / this.connectDistance})`;
                    ctx.lineWidth = 1;
                    ctx.stroke();

                    connections++;

                    // Ograniczenie rysowania trójkątów
                    let trianglesDrawn = 0;
                    for (let k = j + 1; k < this.particles.length && connections < this.maxConnections && trianglesDrawn < 2; k++) {
                        const distanceToThird = Math.hypot(this.particles[j].x - this.particles[k].x, this.particles[j].y - this.particles[k].y);
                        if (distanceToThird < this.connectDistance) {
                            const avgDistance = (distance + distanceToThird) / 2;
                            const intensity = 1 - avgDistance / this.connectDistance;

                            ctx.beginPath();
                            ctx.moveTo(this.particles[i].x, this.particles[i].y);
                            ctx.lineTo(this.particles[j].x, this.particles[j].y);
                            ctx.lineTo(this.particles[k].x, this.particles[k].y);
                            ctx.closePath();

                            ctx.fillStyle = `rgba(255, 255, 255, ${intensity * 0.05})`;
                            ctx.fill();

                            trianglesDrawn++;
                        }
                    }
                }
            }
        }
    }

    animate(currentTime) {
        if (!this.isAnimating) return;

        const isMobile = /Mobi|Android/i.test(navigator.userAgent);
        const targetFPS = isMobile ? 24 : 30;

        const deltaTime = currentTime - this.lastFrameTime;
        if (deltaTime < 1000 / targetFPS) {
            requestAnimationFrame(this.animate.bind(this));
            return;
        }

        this.lastFrameTime = currentTime;

        // Czyszczenie offscreen canvas
        this.offscreenCtx.clearRect(0, 0, this.offscreenCanvas.width, this.offscreenCanvas.height);

        // Rysowanie na offscreen canvas
        this.particles.forEach(particle => particle.update(this.offscreenCtx));
        this.connectParticles(this.offscreenCtx);

        // Kopiowanie obrazu z offscreen canvas na główny canvas
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.ctx.drawImage(this.offscreenCanvas, 0, 0);

        requestAnimationFrame(this.animate.bind(this));
    }

    setupObserver() {
        let timeoutId = null;
        window.addEventListener('scroll', () => {
            clearTimeout(timeoutId);
            this.isAnimating = false;
            timeoutId = setTimeout(() => {
                this.isAnimating = true;
                this.animate(0); // Uruchom ponownie animację po scrollowaniu
            }, 150); // Animacja startuje ponownie po 150ms od zakończenia scrollowania
        });

        this.observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    this.isAnimating = true;
                    this.animate(0); // Wznów animację
                } else {
                    this.isAnimating = false; // Zatrzymaj animację
                }
            });
        }, { threshold: 0.1 });  // Animacja wznawia się, gdy co najmniej 10% elementu jest w widoku

        this.observer.observe(this.element);
    }
}

// Klasa Particle
class Particle {
    constructor(x, y, dx, dy, radius, canvas, ctx, speed) {
        this.x = x;
        this.y = y;
        this.dx = dx;
        this.dy = dy;
        this.radius = radius;
        this.canvas = canvas;
        this.ctx = ctx;
        this.animationSpeed = speed;
    }

    draw(ctx = this.ctx) {
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
        ctx.fillStyle = 'white';
        ctx.fill();
    }

    update(ctx = this.ctx) {
        this.x += this.dx * this.animationSpeed;
        this.y += this.dy * this.animationSpeed;

        if (this.x + this.radius > this.canvas.width || this.x - this.radius < 0) {
            this.dx = -this.dx;
        }

        if (this.y + this.radius > this.canvas.height || this.y - this.radius < 0) {
            this.dy = -this.dy;
        }

        this.draw(ctx);
    }
}
