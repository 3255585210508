import './nav'
import { MeshAnimation } from './mesh-animation'
import { elementExist, docReady } from './utils'
import anime from 'animejs/lib/anime.es.js';

function prepareSourceText(html) {
  let result = ''
  let insideTag = false
  let insideEntity = false
  let letterCounter = 0
  for (let i = 0; i < html.length; i++) {
    const char = html[i]
    if (char === '<') {
      insideTag = true
    }
    if(!insideTag && char === '&') {
      insideEntity = true
    }
    if (insideTag || insideEntity) {
      result += char;
    } else {
      if (char.trim() !== "") {
        const duration = 0.02
        const delay = (letterCounter * (duration * 1000)) / 1000
        letterCounter++
        result += `<span class="letter" style="--animation-delay:${delay}s; --animation-duration:${duration}s;">${char}</span>`
      } else {
        result += char
      }
    }
    if(!insideTag && char === ';') {
      insideEntity = true
    }
    if (char === '>') {
      insideTag = false
    }
  }
  return result
}

docReady(() => {
  elementExist('.home-slider', (query) => {
    const homeSlider = new Splide ( query, {
      type: 'loop',
      arrows: false,
      pagination: false,
      autoplay: true,
      interval: 3500
    } )
    homeSlider.on( 'mounted move', function () {
      document.querySelectorAll(query + ' .container').forEach(item => {
        item.innerHTML = ''
      })
      const number = Number(homeSlider.index + 1).toString().padStart(2, '0')
      const currentSlide = document.querySelector(query + ` #splide01-slide${number}`)
      const currentSource = currentSlide.querySelector('.source-content').innerHTML
      const preparedSource = prepareSourceText(currentSource)
      const currentContainer = currentSlide.querySelector('.container')
      currentContainer.innerHTML = preparedSource
    } )
    homeSlider.mount()
  })

  elementExist('.page-slider', (query) => {
    const pageSlider = new Splide ( query, {
      type: 'fade',
      arrows: false,
      pagination: false,
      autoplay: true,
      interval: 3500
    } )
    pageSlider.on( 'mounted move', function () {
      document.querySelectorAll(query + ' .container').forEach(item => {
        item.innerHTML = ''
      })
      const number = Number(pageSlider.index + 1).toString().padStart(2, '0')
      const currentSlide = document.querySelector(query + ` #splide01-slide${number}`)
      const currentSource = currentSlide.querySelector('.source-content').innerHTML
      const preparedSource = prepareSourceText(currentSource)
      const currentContainer = currentSlide.querySelector('.container')
      currentContainer.innerHTML = preparedSource
    } )
    pageSlider.mount()
  })

  elementExist('.home-tiles .tile', (query, items) => {
    for(let i = 1; i <= items.length; i++) {
      anime({
        targets: `.home-tiles .tile:nth-child(${i}) .icon svg path`,
        strokeDashoffset: [anime.setDashoffset, anime.setDashoffset],
        duration: 0,
        loop: false
      })

      setTimeout(() => {
        anime({
          targets: `.home-tiles .tile:nth-child(${i}) .icon svg path`,
          strokeDashoffset: [anime.setDashoffset, 0],
          easing: 'easeInSine',
          duration: 3000,
          direction: 'alternate',
          loop: false
        })
      }, 500 * i)
    }
  })

  elementExist('.home-tabs-slider', (query) => {
    const homeTabsSlider = new Splide ( query, {
      type: 'loop',
      arrows: true,
      pagination: false,
    } )
    const navigationEl = document.querySelector('.home-tabs .navigation')
    const navigationItems = navigationEl.querySelectorAll('.item')
    navigationEl.style.setProperty('--items', navigationItems.length)
    homeTabsSlider.on( 'mounted move', function () {
      navigationItems.forEach((item, index) => {
        if(index === homeTabsSlider.index) {
          item.classList.add('active')
        } else {
          item.classList.remove('active')
        }
      })
      navigationEl.style.setProperty('--current-item', homeTabsSlider.index + 1)
      const number = Number(homeTabsSlider.index + 1).toString().padStart(2, '0')

      const images = document.querySelectorAll(`.home-tabs #splide02-slide${number} .icons svg`)
      for(let i = 1; i <= images.length; i++) {
        anime({
          targets: `.home-tabs #splide02-slide${number} .icons svg path`,
          strokeDashoffset: [0, anime.setDashoffset],
          duration: 0,
          loop: false
        })

        setTimeout(() => {
          anime({
            targets: `.home-tabs #splide02-slide${number} .icons svg:nth-child(${i}) path`,
            strokeDashoffset: [anime.setDashoffset, 0],
            easing: 'easeInSine',
            duration: 2000,
            direction: 'alternate',
            loop: false
          })
        }, 350 + (50 * i))
      }
    } )
    homeTabsSlider.mount()
    navigationItems.forEach((item, index) => {
      item.addEventListener('click', () => {
        homeTabsSlider.Components.Controller.go(index)
      })
    })
  })

  elementExist('.about-points ul.points-list > li .icon svg', (query, items) => {
    for(let i = 1; i <= items.length; i++) {
      anime({
        targets: `.about-points ul.points-list > li:nth-child(${i}) .icon svg path`,
        strokeDashoffset: [anime.setDashoffset, anime.setDashoffset],
        duration: 0,
        loop: false
      })

      setTimeout(() => {
        anime({
          targets: `.about-points ul.points-list > li:nth-child(${i}) .icon svg path`,
          strokeDashoffset: [anime.setDashoffset, 0],
          easing: 'easeInSine',
          duration: 3000,
          direction: 'alternate',
          loop: false
        })
      }, 500 * i)
    }
  })

  elementExist('.produkty-slider', (query) => {
    const productSlider = new Splide ( query, {
      type: 'loop',
      arrows: true,
      pagination: false,
      perPage: 4,
      autoplay: true,
      interval: 3500,

      breakpoints: {
        1024: {
          perPage: 3,
        },
        720: {
          perPage: 2,
        },
        480: {
          perPage: 1,
        },
      }
    } )

    productSlider.mount()
  })

  elementExist('.product-tabs', (query) => {
    const navItems = document.querySelectorAll(query + ' .sidebar .nav-tab')
    navItems.forEach(item => {
      item.addEventListener('click', function() {
        const tabId = this.dataset.tab
        document.querySelector(query + ' .sidebar .nav-tab.active').classList.remove('active')
        document.querySelector(query + ' .content .content-tab.active').classList.remove('active')
        document.querySelector(`${query} .sidebar .nav-tab[data-tab="${tabId}"]`).classList.add('active')
        document.querySelector(`${query} .content .content-tab[data-tab="${tabId}"]`).classList.add('active')
      })
    })
  } )

  function wrap(el, wrapper) {
    el.parentNode.insertBefore(wrapper, el);
    wrapper.appendChild(el);
  }

  elementExist('#the_content', (query) => {
    const imgs = document.querySelectorAll('#the_content img')
    if(imgs.length) {
      imgs.forEach(element => {
        const link = document.createElement('a')
        link.href = element.src
        link.setAttribute('data-fancybox', 'the_content')
        wrap(element, link)
      });
    }
  })

  elementExist('#header-canvas', (query) => {
    const headerCanvas = new MeshAnimation({
      element: query,
      maxParticles: window.innerWidth > 480 ? 100 : 50
    })
  })

  elementExist('#footer-canvas', (query) => {
    const footerCanvas = new MeshAnimation({
      element: query,
      maxParticles: window.innerWidth > 480 ? 100 : 70
    })
  })

  elementExist('#contact-canvas', (query) => {
    if(window.innerWidth > 480) {
      const contactCanvas = new MeshAnimation({
        element: query,
        connectDistance: 2,
        particleRadius: 4
      })
    }
  })

  elementExist('#about-canvas', (query) => {
    const aboutCanvas = new MeshAnimation({
      element: query,
      maxParticles: window.innerWidth > 480 ? 100 : 40
    })
  })

  elementExist('#product-slider-canvas', (query) => {
    const productSliderCanvas = new MeshAnimation({
      element: query,
      maxParticles: window.innerWidth > 480 ? 100 : 40
    })
  })

  elementExist('#product-sidebar-canvas', (query) => {
    const productSidebarCanvas = new MeshAnimation({
      element: query,
      maxParticles: window.innerWidth > 480 ? 100 : 40
    })
  })

  Fancybox.bind()
  AOS.init()
})
