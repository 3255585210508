import { screen } from './utils'

class Navigation {
  static el = {
    nav: document.querySelector('.nav'),
    dropdowns: document.querySelectorAll('.nav-item.dropdown .nav-link'),
    navToggle: document.querySelector('.nav .nav-toggle')
  }
  static updateNavScrolled() {
    if(window.scrollY > 100) {
      this.el.nav.classList.add('scrolled')
    } else {
      this.el.nav.classList.remove('scrolled')
    }
  }

  static init() {
    if(this.el.nav.classList.contains('always-scrolled')) {
      this.el.nav.classList.add('scrolled')
    } else {
      this.updateNavScrolled()
      addEventListener("scroll", this.updateNavScrolled.bind(this))
    }

    this.el.dropdowns.forEach(el => {
      el.addEventListener('click', (e) => {
        if(!screen("xl", "xxxl")) {
          el.parentNode.classList.toggle('expanded')
          e.preventDefault()
        }
      })
    })

    this.el.navToggle.addEventListener('click', e => {
      this.el.nav.classList.toggle('expanded')
    })
  }
}

Navigation.init()